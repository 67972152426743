import React, { useState } from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/Main"
import Links from "../components/Links"
import Title from "../components/Title"
import Header from "../components/Header"
import BodyWrapper from "../components/BodyWrapper"

const StyledSpan = styled.span`
  color: ${props => (props.isCompleted ? "lightskyblue" : "orange")};
  font-weight: bold;
`

const StyledSelect = styled.select`
  border: none;
  border-bottom: 3px dashed orange;
  background-color: white;
  font-family: "Roboto Mono", monospace;
  font-size: 1rem;
  &:focus {
    outline: none;
    border-bottom: 3px dashed lightskyblue;
  }
  border-bottom: ${props => props.isCompleted && "3px solid lightskyblue"};
`

const StyledInput = styled.input`
  border: none;
  border-bottom: 3px dashed orange;
  font-family: "Roboto Mono", monospace;
  font-size: 1rem;
  &:focus {
    outline: none;
    border-bottom: 3px dashed lightskyblue;
  }
  border-bottom: ${props => props.isCompleted && "3px solid lightskyblue"};
`

const StyledEmail = styled(StyledInput)`
  width: 15rem;
`

const StyledTel = styled(StyledInput)`
  width: 9rem;
`

const StyledButton = styled.button`
  margin-top: 2rem;
  padding: 0.5rem;
  background-color: white;
  border: 2px solid;
  border-color: ${props => (props.disabled ? "lightgray" : "lightskyblue")};
  font-family: "Roboto Mono", monospace;
  font-size: 1rem;
  &:hover {
    cursor: ${props => !props.disabled && "pointer"};
    border-color: ${props => !props.disabled && "orange"};
  }
`

export default () => {
  const [selectedOption, setSelectedOption] = useState("the things")
  const [isCompleted, setIsCompleted] = useState({
    reason: false,
    email: false,
    phone: false,
    name: false,
  })

  const handleBlur = e => {
    setIsCompleted({
      ...isCompleted,
      [e.target.name]: true,
    })
  }

  const handleSelect = e => {
    handleBlur(e)
    setSelectedOption(e.target.value)
  }

  return (
    <Layout>
      <SEO title="Contact Me" />
      <Main>
        <Header>
          <Title text="timothy brunette" to="/" />
          <Links />
        </Header>
        <BodyWrapper>
          <form
            name="contact"
            method="POST"
            action="/contact/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="contact" />
            <p hidden>
              <label>
                Don’t fill this out: <input name="bot-field" />
              </label>
            </p>
            <p>G'day Tim,</p>
            <p>
              I am reaching out because I like{" "}
              <StyledSelect
                name="reason"
                onChange={handleSelect}
                isCompleted={isCompleted.reason}
                defaultValue=""
              >
                <option value="" disabled>
                  the things
                </option>
                <option value="your last name">your last name</option>
                <option value="long walks on the beach">
                  long walks on the beach
                </option>
                <option value="your resume">your resume</option>
                <option value="coffee">coffee</option>
              </StyledSelect>
              . Could you please contact me via my email{" "}
              <StyledEmail
                type="email"
                name="email"
                onBlur={handleBlur}
                isCompleted={isCompleted.email}
              />{" "}
              and/or my phone number{" "}
              <StyledTel
                type="tel"
                name="phone"
                onBlur={handleBlur}
                defaultValue="+61"
                isCompleted={isCompleted.phone}
              />{" "}
              so we can discuss{" "}
              <StyledSpan isCompleted={isCompleted.reason}>
                {selectedOption}
              </StyledSpan>
              .
            </p>
            <p>Cheers,</p>
            <p>
              <StyledInput
                type="text"
                name="name"
                onBlur={handleBlur}
                isCompleted={isCompleted.name}
              />
            </p>
            <StyledButton
              type="submit"
              disabled={
                !(
                  isCompleted.reason &&
                  isCompleted.name &&
                  (isCompleted.email || isCompleted.phone)
                )
              }
            >
              Send the Message
            </StyledButton>
          </form>
        </BodyWrapper>
      </Main>
    </Layout>
  )
}
